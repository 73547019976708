import "./App.css";
import { UserContext } from "./lib/UserContext";
import { useUserData } from "./lib/hooks";
import Navbar from "./components/Navbar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Routes/Home";
import Profile from "./Routes/Profile";
import Challenge from "./Routes/Challenge";

function App() {
  const userData = useUserData();

  return (
    <UserContext.Provider value={userData}>
      <div className="App">
        <Router />
      </div>
    </UserContext.Provider>
  );
}

function Router() {
  return (
    <BrowserRouter>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/Challenge" element={<Challenge />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
