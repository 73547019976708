import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../lib/UserContext";
import { useParams } from "react-router-dom";
import "./Profile.css";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../lib/firebase";
import { CustomToneMapping } from "three";

function Profile() {
  const { user, customUser } = useContext(UserContext);
  const [value, setValue] = useState("");
  const { id } = useParams();
  const [p, setP] = useState({});
  useEffect(() => {
    getDoc(doc(db, "users", id)).then((e) => {
      setP(e.data());
    });
  }, []);
  const onChange = (e) => {
    const val = e.target.value;
    const re = /^(?=[a-zA-Z0-9._]{1,25}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
    if (re.test(val)) {
      setValue(val);
    }
  };
  if (!user || user.uid != id) {
    return (
      <div className="pro">
        <img
          className="img"
          src={p.profilePic || process.env.PUBLIC_URL + "/Empty.png"}
          alt="profile pic"
        />
        <h1 className="name">{p.display || "user"}</h1>
        <h2 className="score">Score: {p.score || "0"}</h2>
      </div>
    );
  } else if (user.uid == id) {
    return (
      <>
        <div className="pro">
          <img
            className="img2"
            src={customUser.profilePic || process.env.PUBLIC_URL + "/Empty.png"}
            alt="profile pic"
          />
          <h1 className="name2">{customUser.display || "user"}</h1>
          <h2 className="score2">Score: {customUser.score || "0"}</h2>
          <input
            className="i"
            placeholder="change username"
            onChange={onChange}
          ></input>
          <button
            className="b"
            onClick={async () => {
              const docRef = doc(db, "users", user.uid);
              updateDoc(docRef, { display: value }).then((e) => {
                window.location.reload();
              });
            }}
          >
            Change Username
          </button>
        </div>
      </>
    );
  }
}

export default Profile;
