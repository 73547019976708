import { initializeApp } from "firebase/app";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCINvGMEKKUX-dYzcL8SSfpzWeYBIdqqPg",
  authDomain: "hw-cp-6407b.firebaseapp.com",
  projectId: "hw-cp-6407b",
  storageBucket: "hw-cp-6407b.appspot.com",
  messagingSenderId: "805986305817",
  appId: "1:805986305817:web:f6008e8b62b268ccfe6788",
  measurementId: "G-ZEDRHQZMQY",
};

const app = initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();
export const db = getFirestore();
export const auth = getAuth(app);

const colRef = collection(db, "users");

export const signInWithGoogle = async () => {
  const result = await signInWithPopup(auth, provider);
  const docRef = doc(db, "users", result.user.uid);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    await setDoc(doc(colRef, result.user.uid), {
      display: result.user.displayName,
      profilePic: result.user.photoURL,
      id: result.user.uid,
      score: 0,
    });
  }
};

export const getUser = async (result) => {
  const j = await getDoc(doc(db, "users", result.uid));
  return j.data();
};

export const signOut = async () => {
  await auth.signOut();
};
