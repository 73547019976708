import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { db } from "../lib/firebase";
import { UserContext } from "../lib/UserContext";
import "./Challenge.css";

function Challenge() {
  const { user, customUser } = useContext(UserContext);
  const [code, setCode] = useState(
    "public class Solution{\n\tpublic static void main(String [] args){\n\t\t\n\t}\n\tpublic int jump(int[] nums){\n\n\t}\n}"
  );
  let doesKnow = false;

  const changeVal = (e) => {
    setCode(e.target.value);
  };

  const submit = (e) => {
    e.preventDefault();
    if (!doesKnow) {
      alert(
        "Please make sure your code is correct, it will be a pain in the ass if you submit and it isnt, fuck you"
      );
      doesKnow = true;
    } else {
      submitCode(code, user, customUser);
    }
  };

  return (
    <form onSubmit={submit}>
      <textarea onChange={changeVal} value={code}></textarea>
      <button type="submit">Submit</button>
    </form>
  );
}

async function submitCode(code, user, customUser) {
  const colRef = collection(db, "question1");
  const docRef = doc(colRef, user.uid);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    await setDoc(doc(colRef, user.uid), {
      submission: code,
      name: customUser.display,
    });
    alert("submitted! if it is right you will get points");
  } else {
    alert("you already submitted jackass");
  }
}

export default Challenge;
