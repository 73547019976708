import { useEffect, useState } from "react";
import { auth, getUser } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getDoc, doc } from "firebase/firestore";
import { db } from "./firebase";

export function useUserData() {
  const [user] = useAuthState(auth);
  const [customUser, setCustomUser] = useState({});
  useEffect(() => {
    if (!user) {
      setCustomUser({});
    }
  }, [user]);
  useEffect(() => {
    let unsubscribe;
    if (user && Object.keys(customUser).length < 1) {
      getDoc(doc(db, "users", user.uid)).then((e) => {
        setCustomUser(e.data());
      });
    }
    return unsubscribe;
  }, [customUser, user]);
  return { user, customUser };
}
