import React from "react";
import { useState, useEffect } from "react";
import "../Routes/Home.css";

function Timer() {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  let next = new Date("Feb 22, 2022 12:55:00").getTime();

  let x = setInterval(() => {
    let now = new Date().getTime();

    let distance = next - now;

    setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
    setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
  }, 0);

  return (
    <div>
      {days <= 0 && minutes <= 0 && hours <= 0 && seconds <= 0 ? (
        <h2 id="timer">RIGHT NOW</h2>
      ) : (
        <h2 id="timer">
          {days} : {hours} : {minutes} : {seconds}
        </h2>
      )}
    </div>
  );
}

export default Timer;
