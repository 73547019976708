import React, { Suspense, useRef } from "react";
import {
  Canvas,
  useLoader,
  useFrame,
  extend,
  useThree,
} from "react-three-fiber";
import { TextureLoader } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { randFloatSpread } from "three/src/math/MathUtils";
import Timer from "../components/Timer";

import "./Home.css";

extend({ OrbitControls });

const CameraControls = () => {
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the OrbitControls component.
  // https://threejs.org/docs/#examples/en/controls/OrbitControls
  const {
    camera,
    gl: { domElement },
  } = useThree();
  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls = useRef();
  useFrame((state) => controls.current.update());
  return <orbitControls ref={controls} args={[camera, domElement]} />;
};

function Pyramid() {
  const myMesh = React.useRef();

  const texture = useLoader(
    TextureLoader,
    process.env.PUBLIC_URL + "/suspicious.jpg"
  );
  useFrame(() => {
    myMesh.current.rotation.x += 0.02;
    myMesh.current.rotation.y += 0.02;
  });
  return (
    <mesh position={[0, 0.5, -6]} ref={myMesh}>
      <cylinderBufferGeometry attach="geometry" args={[0, 4, 5, 4, 1]} />
      <meshStandardMaterial map={texture} />
      {/* <boxBufferGeometry attach="geometry" />
      <meshLambertMaterial attach="material" color="hotpink" /> */}
    </mesh>
  );
}

function starArray() {
  let starArray = [];

  for (let i = 0; i < 300; i++) {
    const [x, y, z] = Array(3)
      .fill()
      .map(() => randFloatSpread(30));
    starArray.push({ x: x, y: y, z: z });
  }
  return starArray;
}

function Home() {
  return (
    <Suspense fallback={<div />}>
      <h2 id="CP">HW Competitive Programming</h2>
      <h2 id="CountDown">Day 2 Chalmers 308</h2>
      <Timer />
      <Canvas>
        {/* <CameraControls /> */}
        <ambientLight intensity={0.6} />
        <spotLight position={[10, 15, 10]} angle={0.3} />
        <Pyramid />
        {starArray().map((name, key) => {
          return (
            <mesh
              key={key}
              scale={[0.02, 0.02, 0.02]}
              position={[name.x, name.y, name.z]}
            >
              <sphereBufferGeometry attach="geometry" />
              <meshStandardMaterial color={"white"} />
            </mesh>
          );
        })}
      </Canvas>
    </Suspense>
  );
}

export default Home;
