import React, { useContext } from "react";
import "./Navbar.css";
import { signInWithGoogle, signOut } from "../lib/firebase";
import { UserContext } from "../lib/UserContext";
import { Link } from "react-router-dom";

function Navbar() {
  const { user, customUser } = useContext(UserContext);

  return (
    <>
      <nav className="navbar">
        <ul className="uln">
          <li className="navli">
            <Link to="/">
              <button type="button" className="btn-logo" onClick={() => {}}>
                HW-CP
              </button>
            </Link>
          </li>
          <li className="navli">
            <Link to="/Challenge">
              <button type="button" className="btn-logo" onClick={() => {}}>
                Todays Challenge
              </button>
            </Link>
          </li>
          <li>
            {!user ? (
              <button className="goog" onClick={() => signInWithGoogle()}>
                Sign In
              </button>
            ) : (
              <>
                <div className="nav-user">
                  <Link to={"/profile/" + customUser.id}>
                    <img
                      src={
                        customUser.profilePic ||
                        process.env.PUBLIC_URL + "/Empty.png"
                      }
                      className="navimg"
                      alt="profile pic"
                      onClick={() => {}}
                    />
                  </Link>
                  <button className="goog" onClick={() => signOut()}>
                    sign out
                  </button>
                </div>
              </>
            )}
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
